body {
    font-family: 'Times New Roman', Times, serif;
    background-color: #100e22;
    color: white;
}
.navbar-toggle
{
    background-color: #100e22;
    border: 0px solid white;
    align-items: center;
    justify-content: center;
    display: flex;
    
}
#sidebar
{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 100%;
     background-color: #100e22;
     border-color: #60338392;
    color: white;
    padding: 20px;
    transition: 0.5s;
    /* transform: translateX(-100%);  */
}
#sidebar-menu
{
    background-color: #100e22;
    height: 100%;
    color: white;

}
#sidebar-submenu1
{
    color: white;
    text-transform: uppercase;
    background-color: #100e22;
    /* border-bottom: 0.1px solid #60338392; */
}
#sidebar-submenu
{

    background-color: #100e22;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;

}
#copyButton
{
    background-color: #60338392;
    color: white;
    border: 0px solid white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;

}
#sidebar-submenu:hover
{
    /* background-color: #60338392; */
    color: white;
}

#nav
{
    /* border-bottom: 2px solid #60338392; */
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 1000;
    left: 0px;
    background-color: #14142b;
}
.row
{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

}
#card
{   border-radius: 7px;
    padding: 10px;
    flex: 1;
    /* background: linear-gradient(90deg,#60338392  0%,  #100e22 100%); */
    /* box-shadow: 0px 0px 10px 0px rgba(93, 90, 90, 0.106); */


}
#card3
{  
    display: flex;
    justify-content: space-between;
    align-items: center;
     border-radius: 7px;
    padding: 10px;
    flex: 1;
    background: linear-gradient(0deg, #100e22 0%, #60338392 100%);


}

.row1
{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

}
#card1
{   border-radius: 7px;
    padding: 10px;
    flex: 1;
    /* background: linear-gradient(90deg, #100e22 0%, #60338392 100%); */
}


button{
    background-color: #603383c1;
    color: white;
    border: 0px solid white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;

}
#toggleBar
{
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 40px;

}
.bodylol
{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#flexinlogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
#close-sidebar
{top: 15px;
    position: fixed;
    right: 0px;
    z-index: 1000;
    right: 10px;
    cursor: pointer;
}
#close-sidebar img
{
    width: 25px;
}
#loginlogo {
    width: 150px;
    height: 150px

}

.signin {
    position: absolute;
    width: 600px;
    background: #222;
    z-index: 1000;
    display: flex;
    justify-content: center;
    
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 9);
}

#loginname {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    margin-top: 20px;
}

#slogan {
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    color: white;
}

#logotext {
    margin-top: 10px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

#companyname {
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}

#logo-mini {
    width: 70px;
}

#menu-title {
    color: white;
}

#Link {
    text-decoration: none;
    cursor: pointer;
}



#dashboardtop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 10px;
    color: white;
    width: 100%;
}

#dashboardtop div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

#trophy {
    width: 50px;
}

#carddivdash {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: black;
    text-transform: uppercase;
    width: 100%;
    background-color: whitesmoke;
    gap: 10px;
    flex-wrap: wrap
}

#carddivdash div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: xx-large;
}

#carddivdash img {
    width: 100px;
    height: 100px;

}

#carddivdash p {
    font-size: 15px;
    text-transform: uppercase;
    color: black;
    font-weight: bold;

}

#formInLogin {
    box-shadow: 0px 0px 10px 0px rgba(93, 90, 90, 0.106);

}

#nav
{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    align-items: center;
}

#bar
{
    width: 30px;
}
#font
{
    width: 100%;
    overflow: break-word;
}
@media screen and (max-width: 600px) {
    .card-title
    {
        font-size: 13px;
        text-transform: uppercase;
    }
    #trophy {
        width: 50px;
    }
    #sidebar
    {
        width: 80vw;
    }
    #font
    {
        font-size: 14px;
        width: 100%;
        
    }
  
    #card
    {   border-radius: 7px;
        padding: 10px;
        flex: 0px;
        min-width:250px;
        width: 100%;
        overflow-x: scroll;
    
    
    }
    #bar
    {
        width: 30px;
    }

    #card1
    {   border-radius: 7px;
        padding: 10px;
        width: 100%;
    }
    

#loginlogo {
    width: 100px;
    height: 100px;

}

.signin {
    position: absolute;
    width: 90vw;
    background: #222;
    z-index: 1000;
    display: flex;
    justify-content: center;

    align-items: center;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 9);
}

#loginname {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    margin-top: 20px;
}

#slogan {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    color: white;
}

#logotext {
    margin-top: 10px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

#companyname {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}
#toggleBar
{
    font-size: 30px;

}
#nav
{
    /* border-bottom: 1px solid #60338392; */
}
}





